import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as GithubSVG } from '../assets/logos/github.svg';
import { ReactComponent as LiveSVG } from '../assets/arrow.svg';

interface ProjectCardProps {
  project: any;
  style: any;
}

const ProjectCard: FC<ProjectCardProps> = ({ project, style }) => {
  return (
    <div
      key={Math.random() * 1000}
      className="grid grid-cols-1 lg:grid-cols-5 shadow-xl w-full gap-2 rounded-[24px] p-4 bg-white"
    >
      <div
        onClick={() => window.open(project.liveLink)}
        className={
          'lg:col-span-3 rounded-[24px] shadow-md overflow-hidden relative h-0 w-full pb-[75%] cursor-pointer ' +
          (style == 1 ? 'lg:order-2' : '')
        }
      >
        <img
          src={project.image.fields.file.url}
          className={
            'absolute left-0 top-0 transition-all duration-[2500ms] ' +
            (project.title.toLowerCase() === 'touge rental'
              ? ' hover:translate-y-[-70%]'
              : '') +
            (project.title.toLowerCase() === 'friendsbook'
              ? ' hover:translate-y-[-35%]'
              : project.title.toLowerCase() == 'pet shop'
              ? 'hover:translate-y-[-65%]'
              : project.title.toLowerCase() == 'netflix clone'
              ? 'hover:translate-y-[-70%]'
              : project.title.toLowerCase() == 'book ease'
              ? 'hover:translate-y-[-60%]'
              : project.title.toLowerCase() == 'miata shop'
              ? 'hover:translate-y-[-25%]'
              : '')
          }
        />
      </div>
      <div className="lg:col-span-2 flex flex-col text-start justify-between pb-1 pt-4">
        <div className="flex flex-col gap-4">
          <label className="text-xl uppercase text-gray-800 font-extrabold px-2 lg:px-4">
            {project.title}
          </label>
          <p className="px-2 lg:px-4 text-gray-600 font-semibold pt-4">
            {project.description}
          </p>
          <div className="flex flex-row justify-start px-2 lg:px-4 gap-2 items-center flex-wrap">
            {project.frameworks.split(' ').map((x: any) => {
              return (
                <label
                  key={Math.random() * 1000}
                  className="bg-gray-100 px-4 py-2 rounded-md shadow-sm text-sm text-slate-600"
                >
                  {x}
                </label>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-start px-2 lg:px-4 gap-2 pb-1 sm:pb-0 sm:gap-4 pt-8 text-sm">
          <button
            className="flex flex-row w-full justify-center sm:w-auto items-center gap-2 cursor-pointer bg-transparent border-2 transition-colors border-black text-black fill-black hover:border-blue-600 hover:text-blue-600 hover:fill-blue-600 px-4 py-2 rounded-xl"
            onClick={() => window.open(project.githubRepository)}
          >
            <GithubSVG className="w-5" />
            <label className="pointer-events-none whitespace-nowrap">
              Open Code
            </label>
          </button>
          <button
            className="flex flex-row w-full justify-center sm:w-auto items-center gap-2 cursor-pointer transition-colors bg-black border-black hover:bg-blue-600 border-2 hover:border-blue-600 text-white fill-white rounded-xl px-4 py-2"
            onClick={() => window.open(project.liveLink)}
          >
            <LiveSVG className="w-5" />
            <label className="pointer-events-none whitespace-nowrap">
              View Live
            </label>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
