import React, { FC, ReactNode } from 'react'
import image from './assets/portfolio.png'
import Header from './components/Header'
import { Helmet } from 'react-helmet'
import { useContentful } from './context/ContentfulContext'
import { ReactComponent as LinkedinSVG } from './assets/logos/linkedin.svg'
import { ReactComponent as GithubSVG } from './assets/logos/github.svg'

interface LayoutProps {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const contentfulContext = useContentful()
  return (
    <>
      <Header />
      <main className="text-lg pt-14">{children}</main>
      <footer className="bg-gray-100 border-t py-4 text-gray-600">
        <div className="max-w-[1000px] mx-auto flex flex-row justify-between w-[95%]">
          <label>© All Rights Reserved {new Date().getFullYear()}</label>
          <div>
            <div className="flex flex-row gap-4 justify-center md:justify-normal">
              <a
                className={
                  'w-[24px] h-[24px] ' +
                  (contentfulContext?.details?.linkedin ? '' : 'hidden')
                }
                href={contentfulContext?.details?.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinSVG
                  height={24}
                  width={24}
                  fill="rgb(72,85,99)"
                  className="transition-all hover:fill-black/90"
                />
              </a>

              <a
                className={
                  'w-[24px] h-[24px] ' +
                  (contentfulContext?.details?.github ? '' : 'hidden')
                }
                href={contentfulContext?.details?.github}
                target="_blank"
                rel="noreferrer"
              >
                <GithubSVG
                  height={24}
                  width={24}
                  fill="rgb(72,85,99)"
                  className="transition-all hover:fill-black/90"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Layout
