import React, { useEffect, useState } from "react";
import { ReactComponent as MenuSVG } from "../assets/menu.svg";
import { motion, useAnimate, useAnimation } from "framer-motion";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const links = [
  {
    title: "Home",
    link: "",
    index: 0,
  },
  {
    title: "About",
    link: "about",
    index: 1,
  },
  {
    title: "Technologies",
    link: "tech",
    index: 2,
  },
  {
    title: "Projects",
    link: "projects",
    index: 3,
  },
  {
    title: "Contact",
    link: "contact",
    index: 4,
  },
];

export default function Header() {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [selectedlink, setLink] = useState("");
  const [scrollTop, setScrollTop] = useState(true);
  const animationControls = useAnimation();

  const scrollTo = (id: string) => {
    const doc = document.getElementById(id);
    doc?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const root = document.getElementById("root");
    if (mobileMenu) {
      root?.classList.add("h-[100vh]");
      root?.classList.add("lg:h-fit");
      animationControls.start("visible");
    } else {
      root?.classList.remove("h-[100vh]");
      root?.classList.remove("lg:h-fit");
      animationControls.set("hidden");
    }
  }, [mobileMenu]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      console.log(document.documentElement.scrollTop);
      if (document.documentElement.scrollTop > 10) setScrollTop(false);
      else setScrollTop(true);
    });
  }, []);

  return (
    <div
      className={
        "shadow-md fixed top-0 left-0 right-0 transition-all bg-gray-100 z-[110] duration-300 " +
        (scrollTop ? "py-4 lg:py-8" : "py-4 lg:py-6 ")
      }
    >
      <div className="max-w-[1000px] w-[95%] mx-auto flex flex-row justify-between items-center text-gray-800">
        <label className="text-gray-900 font-extrabold text-xl">
          Catalin.dev
        </label>
        <div
          className="z-[110] lg:hidden"
          onClick={() => setMobileMenu(!mobileMenu)}
        >
          {!mobileMenu && <MenuRoundedIcon />}
          {mobileMenu && <CloseRoundedIcon />}
        </div>
        <div className="hidden lg:flex flex-row gap-8 font-semibold">
          {links.map((link) => {
            return (
              <a onClick={() => scrollTo(link.link)} className="cursor-pointer">
                {link.title}
              </a>
            );
          })}
        </div>
      </div>
      <div
        className={
          "lg:hidden text-4xl font-semibold text-neutral-800 gap-2 fixed inset-x-0 inset-y-0 transition-all flex flex-col items-center justify-center text-center bg-[rgba(255,255,255,.98)] z-[101]" +
          (mobileMenu ? " opacity-100" : " opacity-0 pointer-events-none")
        }
      >
        {links.map((link) => {
          return (
            <motion.div
              variants={{
                hidden: {
                  opacity: 0,
                  translateX: "-50%",
                },
                visible: {
                  opacity: 1,
                  translateX: 0,
                },
              }}
              initial="hidden"
              animate={animationControls}
              transition={{
                delay: link.index * 0.05 + 0.125,
                duration: 0.35,
              }}
            >
              <button
                className={
                  "px-2 py-6 transition-all cursor-pointer z-[110] duration-500 " +
                  (link.title == selectedlink
                    ? " scale-[1500%]"
                    : " hover:scale-125")
                }
                onClick={() => {
                  setLink(link.title);
                  setTimeout(() => {
                    setMobileMenu(false);
                    setTimeout(() => {
                      scrollTo(link.link);
                    }, 15);
                    setLink("");
                  }, 200);
                }}
              >
                {link.title}
              </button>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
