import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

interface AnimationProps {
  children?: React.ReactNode;
  className?: string;
  direction?: "up" | "down";
}

export default function SlideVertial({
  children,
  className,
  direction = "down",
}: AnimationProps) {
  const divRef = useRef(null);
  const inView = useInView(divRef, { once: true });
  const animationControls = useAnimation();

  useEffect(() => {
    if (inView) {
      animationControls.start("visible");
    }
  }, [inView]);

  return (
    <motion.div
      ref={divRef}
      className={className || "w-full"}
      variants={{
        hidden: {
          opacity: 0,
          translateY: direction == "down" ? 50 : -50,
        },

        visible: {
          opacity: 1,
          translateY: 0,
        },
      }}
      initial={"hidden"}
      transition={{ duration: 0.5, delay: 0.125, ease: "easeOut" }}
      animate={animationControls}
    >
      {children}
    </motion.div>
  );
}
