import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

interface RevealProps {
  children?: React.ReactNode;
  className?: string;
  color?: string;
}

export default function Reveal({ children, className, color }: RevealProps) {
  const overlayController = useAnimation();
  const animationController = useAnimation();

  const divRef = useRef(null);
  const inView = useInView(divRef, { once: true });

  useEffect(() => {
    if (inView) {
      overlayController.start("hidden");
      animationController.start("visible");
    }
  }, [inView]);

  return (
    <div
      ref={divRef}
      className={className || "relative w-fit z-[100] overflow-hidden"}
    >
      <motion.div
        className="z-[1]"
        variants={{
          hidden: {
            opacity: 0,
            translateY: 20,
          },
          visible: {
            opacity: 1,
            translateY: 0,
          },
        }}
        initial="hidden"
        animate={animationController}
        transition={{ delay: 0.25, duration: 0.35 }}
      >
        {children}
      </motion.div>
      <motion.div
        className="absolute left-0 top-4 w-full h-[50%] bg-blue-600 z-[10]"
        variants={{
          visible: {
            left: 0,
          },
          hidden: {
            left: "100%",
          },
        }}
        initial="visible"
        animate={overlayController}
        transition={{ duration: 0.25, delay: 0.125 }}
      ></motion.div>
    </div>
  );
}
