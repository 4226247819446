import React, { useEffect, useRef } from "react";
import { motion, useAnimate, useAnimation } from "framer-motion";
import { useInView } from "framer-motion";

export default function SlideHorizontal({
  children,
  direction = "left",
  className,
}: {
  children?: React.ReactNode;
  direction?: "left" | "right";
  className?: string;
}) {
  const divRef = useRef(null);
  const animationControls = useAnimation();
  const isInView = useInView(divRef, { once: true });

  useEffect(() => {
    if (isInView) {
      animationControls.start("visible");
    }
  }, [isInView]);

  return (
    <motion.div
      className={className}
      ref={divRef}
      variants={{
        hidden: {
          opacity: 0,
          translateX: direction == "left" ? "-20%" : "20%",
        },
        visible: {
          opacity: 1,
          translateX: 0,
        },
      }}
      transition={{ duration: .5, delay: 0.125, ease: 'easeOut' }}
      initial="hidden"
      animate={animationControls}
    >
      {children}
    </motion.div>
  );
}
