import React, { useEffect } from 'react';
import css from '../assets/logos/css.png';
import html from '../assets/logos/html.png';
import react from '../assets/logos/react.png';
import vscode from '../assets/logos/vscode.png';
import js from '../assets/logos/javascript.png';
import ts from '../assets/logos/typescript.png';
import contentful from '../assets/logos/contentful.png';
import tailwind from '../assets/logos/tailwind.png';
import mongo from '../assets/logos/mongo.webp';
import firebase from '../assets/logos/firebase.png';
import python from '../assets/logos/python.png';
import next from '../assets/logos/next.webp';
import figma from '../assets/logos/figmaLogo.webp';
import node from '../assets/logos/node.png';
import postman from '../assets/logos/postman.png';
import expo from '../assets/logos/expo.png';
import prisma from '../assets/logos/prisma.png';
import sass from '../assets/logos/sass.png';
import socketIo from '../assets/logos/socket.io.png';
import SlideVertial from './Animations/SlideVertial';
import Reveal from './Animations/Reveal';

const tech = [
  {
    title: 'Markup & Languages',
    content: [
      { title: 'HTML', imageURL: html },
      { title: 'CSS', imageURL: css },
      { title: 'SASS', imageURL: sass },
      { title: 'JavaScript', imageURL: js },
      { title: 'TypeScript', imageURL: ts },
      { title: 'Python', imageURL: python },
    ],
  },
  {
    title: 'Frameworks',
    content: [
      { title: 'React.js', imageURL: react },
      { title: 'Next.js', imageURL: next },
      { title: 'Node.js', imageURL: node },
      { title: 'Expo Aop', imageURL: expo },
    ],
  },
  {
    title: 'Tools',
    content: [
      { title: 'MongoDB', imageURL: mongo },
      { title: 'Prisma', imageURL: prisma },
      { title: 'Firebase', imageURL: firebase },
      { title: 'SocketIO', imageURL: socketIo },
      { title: 'Tailwind CSS', imageURL: tailwind },
      { title: 'Postman', imageURL: postman },
    ],
  },
  {
    title: 'Others',
    content: [
      { title: 'Contentful', imageURL: contentful },
      { title: 'VsCode', imageURL: vscode },
      { title: 'Figma', imageURL: figma },
    ],
  },
];

export default function Technologies() {
  useEffect(() => {
    console.log(html);
  }, []);
  return (
    <div
      className="flex flex-col gap-8 items py-8 lg:py-16 max-w-[1000px] w-[95%] mx-auto"
      id="tech"
    >
      <div className="flex flex-col gap-2">
        <Reveal>
          <label className="text-lg text-blue-600 font-extrabold text-start block uppercase">
            technologies
          </label>
        </Reveal>

        <Reveal>
          <p className="text-2xl text-gray-800 font-bold">
            Developer that possesses expertise in several technologies
          </p>
        </Reveal>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 gap-y-8 pt-8">
        {tech.map((techElement) => {
          return (
            <SlideVertial>
              <div className="p-4 rounded-[8px] bg-white shadow-xl w-full flex flex-col gap-2 items-start text-start">
                <label className="text-xl uppercase text-gray-800 font-extrabold block text-start">
                  {techElement.title}
                </label>
                <div className="h-[1px] w-full bg-black/25 mb-4" />
                <div className="flex flex-row flex-wrap gap-4">
                  {techElement.content.map((tech: any) => {
                    return (
                      <div className="relative group w-fit h-fit">
                        <img
                          src={tech.imageURL as string}
                          className="h-8 w-8"
                          title="teafdasfds"
                          alt={tech.title}
                        />
                        <div className="absolute px-2 py-1 bg-neutral-800 text-white rounded-md -top-10 left-4 translate-x-[-50%] whitespace-nowrap transition-all z-[120] group-hover:opacity-80 group-hover:translate-y-0 translate-y-[20%] duration-300 pointer-events-none opacity-0">
                          <label>{tech.title}</label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </SlideVertial>
          );
        })}
      </div>
    </div>
  );
}
