import React, { useEffect } from "react";
import phone from "../assets/phone.svg";
import letter from "../assets/mail-svgrepo-com.svg";
import { useContentful } from "../context/ContentfulContext";
import ContactForm from "./ContactForm";
import Reveal from "./Animations/Reveal";
import SlideVertial from "./Animations/SlideVertial";

export default function Contact() {
  const contentfulContext = useContentful();

  return (
    <div
      className="mx-auto max-w-[1000px] px-6 lg:px-0 flex flex-col justify-between items-start py-8 lg:py-16 gap-8 lg:gap-12"
      id="contact"
    >
      <div>
        <Reveal>
          <label className="text-lg text-blue-600 font-extrabold text-start block uppercase">
            Contact me
          </label>
        </Reveal>
        <Reveal>
          <label className="text-2xl text-gray-800 font-bold">
            Let's get in touch!
          </label>
        </Reveal>
      </div>
      <SlideVertial>
        <ContactForm className="p-6 md:p-12" />
      </SlideVertial>
    </div>
  );
}

const contactImageClass =
  "w-[64px] h-[64px] rounded-full bg-gray-100 shadow-md overflow-hidden";
