import React from 'react';
import Layout from './Layout';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import ContentfulProvider from './context/ContentfulContext';
import Technologies from './components/Technologies';

function App() {
  return (
    <ContentfulProvider>
      <Layout>
        <Hero />
        <About />
        <div className="bg-gray-100">
          <Technologies />
        </div>
        <Projects />
        <div className="bg-gray-100">
          <Contact />
        </div>
      </Layout>
    </ContentfulProvider>
  );
}

export default App;
