import React from "react";
import project from "../assets/project1.webp";
import github from "../assets/logos/github.svg";
import arrow from "../assets/arrow.svg";
import { useContentful } from "../context/ContentfulContext";
import ProjectCard from "./ProjectCard";
import SlideHorizontal from "./Animations/SlideHorizontal";
import Reveal from "./Animations/Reveal";

export default function Projects() {
  const contentfulContext = useContentful();
  const index = 0;

  return (
    <div className="max-w-[1000px] mx-auto px-6 lg:px-0 py-8 lg:py-16" id="projects">
      <div className="flex flex-col gap-2 text-start">
        <Reveal>
          <label className="text-lg text-blue-600 font-extrabold text-start block uppercase">
            Projects
          </label>
        </Reveal>
        <Reveal>
          <label className="text-2xl text-gray-800 font-bold">
            Each project embodies its own distinctive journey of development.
          </label>
        </Reveal>
        <label className="text-xs text-gray-400 font-bold">
          Note: Servers are hosted on Render so you need to wait 10-15 seconds
        </label>
        <div className="flex flex-col gap-16 pt-8">
          {contentfulContext?.projects?.map((project: any) => {
            const index = contentfulContext.projects.indexOf(project);
            return (
              <SlideHorizontal direction={index % 2 == 0 ? "left" : "right"}>
                <div key={Math.random() * 1000}>
                  <ProjectCard project={project.fields} style={index % 2} />
                </div>
              </SlideHorizontal>
            );
          })}
        </div>
      </div>
    </div>
  );
}
