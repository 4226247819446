import React from "react";
import setup from "../assets/setup.png";
import css from "../assets/css.png";
import developerCirlce from "../assets/webdev.svg";
import { useContentful } from "../context/ContentfulContext";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Reveal from "./Animations/Reveal";
import SlideVertial from "./Animations/SlideVertial";
import SlideHorizontal from "./Animations/SlideHorizontal";

export default function About() {
  const contentfulContext = useContentful();

  return (
    <div
      className="max-w-[1000px] mx-auto py-8 lg:py-16 flex flex-col gap-40 w-[95%]"
      id="about"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 bg-no-repeat bg-center bg-cover gap-16 lg:px-0">
        <SlideHorizontal>
          <div
            className="relative w-[80%] h-0 pb-[60%] lg:w-full lg:pb-[75%] mx-auto bg-bottom rounded-3xl"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <img
              src={contentfulContext?.details?.aboutImage?.fields?.file?.url}
            />
            <div className="absolute right-[-50px] overflow-hidden shadow-xl border-black bottom-[-50px] w-[150px] bg-white h-[150px] bg-center bg-cover rounded-full">
              <div className="relative">
                <img src={developerCirlce} className="spin z-0" />
                <div className="absolute top-[30px] left-0 w-full h-full text-center my-auto text-7xl z-2 pointer-events-none">
                  👨🏻‍💻
                </div>
              </div>
            </div>
          </div>
        </SlideHorizontal>
        <div className="flex flex-col gap-2">
          <Reveal>
            <label className="text-lg mt-2 text-blue-600 font-extrabold text-start block uppercase">
              about me
            </label>
          </Reveal>
          <Reveal>
            <p className="text-2xl text-gray-800 font-bold">
              {documentToReactComponents(
                contentfulContext?.details?.aboutTitle
              )}
            </p>
          </Reveal>
          <SlideVertial>
            <p className="text-lg text-gray-600 mt-6">
              {documentToReactComponents(
                contentfulContext?.details?.aboutDescription
              )}
            </p>
          </SlideVertial>
        </div>
      </div>
    </div>
  );
}
