import React from 'react'
import { ReactComponent as LinkedinLogo } from '../assets/logos/linkedin.svg'
import { ReactComponent as GithubLogo } from '../assets/logos/github.svg'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useContentful } from '../context/ContentfulContext'
import SlideHorizontal from './Animations/SlideHorizontal'
import Reveal from './Animations/Reveal'

export default function Hero() {
  const contentfulContext = useContentful()

  return (
    <div className="bg-gray-100 items-center justify-center flex flex-col gap-16 md:gap-32 py-16 sm:py-24 lg:py-40">
      <div className="flex flex-col lg:flex-row justify-between align-top gap-12 md:gap-16 lg:gap-24 max-w-[1000px] px-6 lg:px-0 md:mx-auto">
        <div className="flex flex-col text-center items-center lg:items-start lg:text-start lg:pt-16 gap-8 ">
          <Reveal>
            <label className="font-extrabold text-5xl">
              {documentToReactComponents(contentfulContext?.details?.heroTitle)}
            </label>
          </Reveal>
          <Reveal>
            <label className="text-xl font-normal text-gray-700">
              {documentToReactComponents(
                contentfulContext?.details?.heroDescription
              )}
            </label>
          </Reveal>
          <Reveal>
            <div className="flex flex-row gap-4 justify-center md:justify-normal">
              <a
                className={
                  'w-[32px] h-[32px] ' +
                  (contentfulContext?.details?.linkedin ? '' : 'hidden')
                }
                href={contentfulContext?.details?.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinLogo className="hover:fill-black/75 transition-all" />
              </a>

              <a
                className={
                  'w-[32px] h-[32px] ' +
                  (contentfulContext?.details?.github ? '' : 'hidden')
                }
                href={contentfulContext?.details?.github}
                target="_blank"
                rel="noreferrer"
              >
                <GithubLogo className="hover:fill-black/75 transition-all" />
              </a>
            </div>
          </Reveal>
        </div>
        <SlideHorizontal
          direction="right"
          className="lg:order-[2] order-[-1] min-w-[20rem] h-[20rem]  md:min-w-[25rem] md:h-[25rem] mx-auto"
        >
          <div className="border-4 border-black h-[20rem] md:min-w-[25rem] md:h-[25rem] relative overflow-hidden morph mx-auto">
            <img
              className="absolute left-0 -top-10 w-[100%] h-[120%] object-cover"
              src={contentfulContext?.details?.profileImage.fields.file.url}
              title="image"
              alt="profilephoto"
            ></img>
          </div>
        </SlideHorizontal>
      </div>
    </div>
  )
}
