import React, {
  ReactNode,
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from "react";
import { client } from "../lib/contentful";
import { prototype } from "events";

interface ContentfulProviderProps {
  children: ReactNode;
}

interface ContentfulContextProps {
  details: any;
  projects: any;
}

const ContentfulContext = createContext<ContentfulContextProps | null>(null);

const ContentfulProvider: FC<ContentfulProviderProps> = ({ children }) => {
  const [portfolioDetails, setPortfolioDetails] = useState<any>(null);
  const [projects, setProjects] = useState<any>();

  const fetchData = async () => {
    const details = await client.getEntries({
      content_type: "portfolioDetails",
    });
    const projects = await client.getEntries({
      content_type: "portfolioProject",
      order: ["fields.order"],
    });

    setProjects(projects.items);
    console.log(details.items[0].fields);
    setPortfolioDetails(details.items[0].fields);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContentfulContext.Provider
      value={{ details: portfolioDetails, projects: projects }}
    >
      {children}
    </ContentfulContext.Provider>
  );
};

export function useContentful() {
  return useContext(ContentfulContext);
}

export default ContentfulProvider;
